import React from "react";
import PageWrapper from "../components/PageWrapper";
import SEO from '../components/SEO';

const Contact = () => { 
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: 'site-header--menu-left',
          headerFluid: false,
          version: 'golf-version',
          footerStyle: 'digma',
        }}>
        <SEO
          title="B2B Golf Competition"
          description="50% OFF for a year! If you make a hole-in-one"
        />
        {/* <Hero className="position-relative pt-15 pt-md-31 pt-lg-25 pb-14 pb-md-19 pb-lg-33 position-relative overflow-hidden" />  
        <Wave color="#F7F9FC" />          */}
        <div style={{height:"100vh"}} className="grey d-flex align-items-center justify-center">
          <div className="container">
            {/* Contact Form */}
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h3 className="font-size-9 Popups mt-lg-0">B2B Golf Competition</h3>
                <h4 className="font-size-7 mt-lg-0 mt-md-30">50% OFF for a year! If you make a hole-in-one</h4>
                <p>Sign up to our newsletter and if you make a hole-in-one we will give you a 50% OFF voucher for your first year with Digma.</p>
                <form
                    name="b2b-golf-competition"
                    method="POST"
                    action="thank-you"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-aos="fade-up"
                    data-aos-duration={1100}
                    data-aos-once="true"
                  >
                    <input type="hidden" name="form-name" value="b2b-golf-competition" />
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Full Name"
                        id="name"
                        required
                      />
                    </div>                  
                    {/* Email */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Work Email"
                        id="email"
                        required
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                    <input
                        type="text"
                        name="company"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Company"
                        id="company"
                        required
                      />
                    </div>
                    <div className="button">
                      <button
                        type="submit"
                        href="/#"
                        className="btn btn-digma h-55 w-100 rounded-4"
                      >
                        Submit
                      </button>
                    </div>
                  </form>             </div>
 
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
